

#imgCon {
    /* width: 1000px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    width: 100%;
    align-items: center;
}
#imgConM {
    display: "flex" ;
    justify-content: "space-around";
    flex-direction: "column";
    flex-wrap: 'wrap';
    align-items:"center"; 
}

.box {
    width: 260px;
    overflow: hidden;
    /* background: #f6f6f6; */
    box-sizing: border-box;
    padding: 0 15px 25px 15px;
    cursor: pointer;
}

.box div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #535353;
    text-decoration: none;
}

.box div:hover {
    color: #0679e6;
}

.imgBox {
    width: 200px;
    height: 186.67px;
    overflow: hidden;
}

.imgBox img {
    width: 100%;
    /* height: 100%; */
    transition: all 0.6s linear;
}

.source {
    /* border: 1px solid #535353;
    padding: 6px 20px;
    border-radius: 25px;
    margin: 20px; */
}

.title {
    margin-bottom: 20px;
    font-size: 20px;
}

.detail {
    font-size: 14px;
    line-height: 160%;
    text-align: justify;
    width: 100%;
}

/*鼠标hover效果*/

.box>div::after {
    content: '';
    width: 260px;
    position: relative;
    background: #0679e6;
    bottom: -30px;
    height: 0px;
    transition: height 0.3s linear;
    /*利用伪类高度，实现动画效果*/
}

.box>div:hover::after {
    height: 10px;
}

.box:hover img {
    transform: scale(1.1);
}

.box:hover .source {
    /* background: #0679e6;
    border-color: #0679e6; */
    color: #0679e6;
}
.box:hover {
    border: #0679e6 1px solid;
}

.linkToPage:hover{
    cursor: pointer;
}