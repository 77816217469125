.mingSelfDefTable{
    border-collapse: collapse;
}

.mingSelfDefTable td{
    border: 1px solid black;
}

.pcShowfalse{
    display: none;
}